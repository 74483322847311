<template>



<div>

 <div class="section-shaped my-0 skew-separator skew-mini ">
        <v-img
          class="page-header header-filter  header-small "
          max-height="390px"
          lazy-src="/media/biz-header.jpg"
          src="/media/store.jpg"
          gradient="to top right, rgba(0,0,0,.53), rgba(25,32,72,.7)"
        >
          <div class="container " style="padding-bottom: 65px;">
            <div class="md-layout justify-md-center ">
              <div
                class="md-layout-item md-size-66 md-small-size-100 mt-35 text-center"
              >
                <div class="lato-header  font-weight-bolder text-white">
                   Goose  Admin
                </div>
                <div class="mt-5">
                  <h4 class="text-white "></h4>
                </div>
              </div>
            </div>
          </div>
        </v-img>
      </div>

      <div class="main main-raised" style="background:#f6f7ff; min-height:700px;">
<div class="section">
        <div class="container">
    <div class="pt-6">
      <div style="">
        <div class="row px-6 ">
          <div class="col-md-10 col-12">
            <div class="text-center">
   
            </div>
          </div>
          <div class="col-md-6 col-12">
            

            <v-card class="mb-6">
              <v-card-text>
                <div class="d-flex justify-space-between">
                  <div class="pr-4">
                    <h5 class="text-18">Seller Management</h5>
                    <p>
                      Create and manage Seller accounts
                    </p>
                  </div>
                  <v-img
                    src="/img/application.jpg"
                    style="height: 150px; width: 150px"
                  />
                </div>
              </v-card-text>

              <v-card-actions class="primary--text grey lighten-5 py-4">
              <v-btn    
              to="/account/profile" nuxt
           
              
              
              text>   Manage Sellers </v-btn>
              </v-card-actions>
            </v-card>
          </div>
         
          <div class="col-md-6 col-12">
            <v-card class="mb-6"
            >
             
                <v-card-text>
                  <div class="d-flex justify-space-between">
                    <div class="pr-4">
                      <h5 class="text-18">Buyer Management</h5>
                      <p>
                        Create new Buyers, edit profiles, and add users to accounts.
                      </p>
                    </div>
                    <v-img
                    src="/img/invoice.jpg"
                    style="height: 150px; width: 150px"
                  />
                  </div>
                </v-card-text>

                <v-card-actions
                  class="v-card__actions primary--text grey lighten-5 py-4"
                  style="cursor: pointer"
                >
                 <v-btn 
                 
                  to="/account/invoices" nuxt
                 text>  Manage Buyers  </v-btn>

                </v-card-actions>
             
            </v-card>
          </div>
          <div class="col-md-6 col-12">
            <v-card
              class="mx-auto"
              
            >
                <div class="v-card__text pa-4">
                  <div class="d-flex justify-space-between">
                    <div class="pr-4">
                      <h5 class="text-18">User Management</h5>
                      <p>
                        Create new Users and assign them to Companies
                      </p>
                    </div>
                      <v-img
                    src="/img/profile.png"
                    style="height: 150px; width: 150px"
                  />
                  </div>
                </div>

                <v-card-actions
                  class="primary--text grey lighten-5 py-4"
                  style="cursor: pointer"
                >
                                  <v-btn  
                                  
                                   to="/account/settings" nuxt
                                   text >
Create Users  </v-btn>
                </v-card-actions>
            </v-card>
          </div>
              <div class="col-md-6 col-12">
            <v-card
              class="mx-auto"
              
            >
                <div class="v-card__text pa-4">
                  <div class="d-flex justify-space-between">
                    <div class="pr-4">
                      <h5 class="text-18">Billing & Admin</h5>
                      <p>
                       Manage Company Billings and Reportings
                      </p>
                    </div>
                      <v-img
                    src="/img/receipt.jpg"
                    style="height: 150px; width: 150px"
                  />
                  </div>
                </div>
   
                <v-card-actions
                  class=" primary--text grey lighten-5 py-4"
                  style="cursor: pointer"
                >
                <v-btn   
                 to="/account/order-list" nuxt
                
                
                
                text >
                  Manage Now 
                </v-btn>
                </v-card-actions>
            </v-card>
          </div>
          <div class="col col-10">
       
          </div>
        </div>
      </div>
    </div>
        </div>

</div>

      </div>
  

    




</div>
</template>

<script>
import SET_BREADCRUMB  from "@/core/services/store/breadcrumbs.module";
import  InfoAreas from "../../components/base/InfoAreas.vue";
import gql from "graphql-tag";
import { mapGetters, mapActions } from "vuex";


  export default {
 components: {

  },





  apollo: {
    adcategory: {
      query: gql`
      query Adcategory {
  adcategory(id: 1) {
          id
          name
          advertisements {
            id
            title
            text
          }
        }
      }
      `,
      variables() {
        return {
          id: this.routeParam
        };
      }
    }
  },









    data () {
      return {

         adcategory: {},
           routeParam: this.$route.params.id,
           
         
 image: require("@/assets/img/bg9.jpg"),
        componentKey: 0,
      api_url: process.env.VUE_APP_STRAPI_API_URL,

        countryData: {
          US: 2920,
        
        },
        dailySalesChart: {
          data: {
            labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
            series: [
              [12, 17, 7, 17, 23, 18, 38],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        dataCompletedTasksChart: {
          data: {
            labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
            series: [
              [230, 750, 450, 300, 280, 240, 200, 190],
            ],
          },
          options: {
            lineSmooth: this.$chartist.Interpolation.cardinal({
              tension: 0,
            }),
            low: 0,
            high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
            chartPadding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        },
        emailsSubscriptionChart: {
          data: {
            labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
            series: [
              [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895],

            ],
          },
          options: {
            axisX: {
              showGrid: false,
            },
            low: 0,
            high: 1000,
            chartPadding: {
              top: 0,
              right: 5,
              bottom: 0,
              left: 0,
            },
          },
          responsiveOptions: [
            ['screen and (max-width: 640px)', {
              seriesBarDistance: 5,
              axisX: {
                labelInterpolationFnc: function (value) {
                  return value[0]
                },
              },
            }],
          ],
        },
      

        
  
        sales: [
          {
            partner: 'Bobs discount',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/US.png',
            salesInM: 2920,
          },
          {
            partner: 'Outdoor World',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/DE.png',
            salesInM: 1300,
          },
          {
            partner: 'Fast Fashion',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/AU.png',
            salesInM: 760,
          },
          {
            partner: 'Acme Local',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/GB.png',
            salesInM: 690,
          },
          {
            partner: 'Pop Up Store',
            flag: 'https://demos.creative-tim.com/vue-material-dashboard-pro/img/flags/RO.png',
            salesInM: 600,
          },
       
        ],
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
 mounted() {
    setTimeout(() => {
      this.componentKey += 1;this.$store.dispatch(SET_BREADCRUMB, [{ path: "/dashboard" }]);
    }, 500);
  },

    computed: {
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
      features5() {
      return {
        backgroundImage: `url(${this.image})`
      };
    }
    },







    methods: {
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>





